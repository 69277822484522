import React from "react"
import { Button } from "@components/common/Button"

import { Insurance } from "../../types/insurance"

interface InsuranceConfirmPaymentModalProps {
  isOpen: boolean
  insurance: Insurance
  variant: string
  onClose: () => void
  onConfirm: () => void
}

const InsuranceConfirmPaymentModal: React.FC<InsuranceConfirmPaymentModalProps> = ({
  isOpen,
  insurance,
  variant,
  onClose,
  onConfirm,
}) => {
  if (!isOpen) return null

  const refundPrice = insurance.private ? insurance.price : insurance.price * (insurance.refund / 100)
  const finalPrice = insurance.price - refundPrice

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div className="max-w-lg mx-4 overflow-y-auto rounded-lg bg-white p-4">
        <div className="flex justify-end">
          <button onClick={onClose} className="text-gray-500">
            <svg className="size-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="mb-6 w-full flex flex-col items-center">
          <h2 className="mb-3 w-5/6 text-center text-xl font-bold text-dark1">
            {variant === "v1"
              ? "Ernährungskurs bezahlt von deiner Krankenkasse:"
              : "Dein Weg zur Erstattung – Alle Details auf einen Blick"}
          </h2>

          <div className="space-y-2 w-full">
            <div className="flex items-center justify-between">
              {variant === "v1" && <span>Preis Ernährungskurs:</span>}
              {variant === "v2" && (
                <div className="flex flex-col">
                  <span className="">Präventionskurs:</span>
                  <span className="text-xs text-dark3">(bequem per Rechnung)</span>
                </div>
              )}
              <span>{insurance.price.toFixed(2).replace(".", ",")} €</span>
            </div>
            <div className="flex items-center justify-between">
              <span>Fastic Plus gratis dazu</span>
              <span className="font-semibold">0,00 €</span>
            </div>
            <div className="flex items-center justify-between">
              {variant === "v1" && (
                <span className="font-semibold">
                  {insurance.private ? "Erstattung bis zu 100%" : `Erstattung ${insurance.refund}%`}
                </span>
              )}
              {variant === "v2" && (
                <div className="flex flex-col">
                  <span className="">
                    {insurance.private ? "Erstattung bis zu 100%" : `Erstattung ${insurance.refund}%`}
                  </span>
                  <span className="text-xs text-dark3">(nach Kursabschluss)</span>
                </div>
              )}
              <span className="font-semibold">{(refundPrice * -1).toFixed(2).replace(".", ",")} €</span>
            </div>
            <div className="flex items-center justify-between border-t-2 border-dark1 pt-4">
              <span className="text-dark1">Am Ende zahlst du effektiv</span>
              <span className="text-lg font-bold ">{finalPrice.toFixed(2).replace(".", ",")} €</span>
            </div>
          </div>

          <div className="w-full text-left mt-2">
            <p className="text-red-500 text-lg font-bold">
              {insurance.private
                ? `Die Erstattung der ${insurance.name} ist abhängig von deinem Tarif.`
                : `Die ${insurance.name} erstattet ${insurance.refund}% der Kosten!`}
            </p>
          </div>
        </div>

        <Button primary label="Jetzt zum genannten Preis bestellen" className="mb-4 w-full" onClick={onConfirm} />
      </div>
    </div>
  )
}

export default InsuranceConfirmPaymentModal
