import React, { useRef, useState } from "react"
import Image from "next/image"
import { Button } from "@components/common/Button"

import { trackInsuranceSearchStarted, trackInsuranceSelected } from "../../services/client/events"
import { germanInsurances, Insurance } from "../../types/insurance"

interface InsuranceModalProps {
  variant: string
  isOpen: boolean
  onClose: () => void
  onSelect: (insurance: Insurance) => void
}

const InsuranceModal: React.FC<InsuranceModalProps> = ({ variant, isOpen, onClose, onSelect }) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedInsurance, setSelectedInsurance] = useState<Insurance | null>(null)
  const hasTrackedSearch = useRef(false)

  const filteredInsurances = germanInsurances.filter((insurance) =>
    insurance.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const handleInsuranceSelect = (insurance: Insurance) => {
    setSelectedInsurance(insurance)
    setSearchTerm(insurance.name)
    trackInsuranceSelected(insurance.name)
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = e.target.value
    setSearchTerm(newSearchTerm)

    if (!hasTrackedSearch.current && newSearchTerm.length >= 2) {
      trackInsuranceSearchStarted(newSearchTerm)
      hasTrackedSearch.current = true
    }

    if (selectedInsurance) {
      setSelectedInsurance(null)
    }
  }

  React.useEffect(() => {
    if (!isOpen) {
      hasTrackedSearch.current = false
    }
  }, [isOpen])

  const handleContinue = () => {
    if (selectedInsurance) {
      onSelect(selectedInsurance)
      onClose()
    }
  }

  if (!isOpen) return null

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div className="min-h-full w-full max-w-lg overflow-y-auto rounded-lg bg-white p-4">
        <div className="mt-4 flex justify-end">
          <button onClick={onClose} className="text-gray-500">
            <svg className="size-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="mb-6 w-full">
          <h2 className="mb-2 text-left text-2xl font-bold text-dark1">Erstattung durch deine Krankenkasse</h2>
          <div className="flex flex-row gap-4">
            <div className="flex flex-col justify-center gap-2 text-left">
              {variant === "v1" && <p className="text-sm">Prüfe hier, wie viel dir deine Krankenkasse erstattet.</p>}
              {variant === "v2" && (
                <p className="text-sm">
                  Wähle deine Krankenkasse aus und wir sagen dir wie hoch der Erstattungsbetrag ist.
                </p>
              )}

              <p>
                Kurs-ID: <span className="font-bold">KU-ER-GSO6FU</span>
              </p>
            </div>
            <Image
              src="/images/insurance/icon_zeritifiziert.svg"
              alt="Zertifizierungssiegel"
              width={125}
              height={125}
              className="object-contain"
            />
          </div>
        </div>
        <div className="relative mb-6 w-full rounded-lg border shadow-lg">
          <div className="relative m-4">
            <div className="mb-4 flex items-center">
              <h2 className="text-lg font-bold">Wähle deine Krankenkasse</h2>
            </div>

            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Suche deine Krankenkasse..."
              className="mb-4 w-full rounded-lg border p-2"
              autoComplete="off"
              autoCapitalize="off"
              autoCorrect="off"
            />

            {searchTerm.length >= 2 && !selectedInsurance && (
              <div className="absolute z-50 -mt-3 max-h-60 w-full space-y-2 overflow-y-auto rounded-md border border-slate-200 bg-white shadow-lg">
                <ul className="divide-y divide-slate-200">
                  {filteredInsurances.length === 0 ? (
                    <li className="px-4 py-2 text-sm font-medium text-slate-500">Keine Ergebnisse</li>
                  ) : (
                    filteredInsurances.map((insurance) => (
                      <li
                        key={insurance.name}
                        onClick={(e) => {
                          e.preventDefault()
                          handleInsuranceSelect(insurance)
                        }}
                        className="cursor-pointer px-4 py-2 text-sm font-medium text-slate-500 hover:bg-slate-50"
                      >
                        {insurance.name}
                      </li>
                    ))
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className="mb-4 w-full">
          <div className={`space-y-4 transition-all duration-300 ${!selectedInsurance && "blur-sm"}`}>
            <div className="flex items-center justify-between">
              {variant === "v1" && <span className="text-dark3">Preis:</span>}
              {variant === "v2" && (
                <div className="flex flex-col">
                  <span className="font-semibold text-dark1">Du streckst vor:</span>
                  <span className="text-xs text-dark3">(bequem per Rechnung)</span>
                </div>
              )}
              <span className="text-dark1">{selectedInsurance?.price.toFixed(2) || "119,00"} €</span>
            </div>
            <div className="flex items-center justify-between">
              {variant === "v1" && (
                <div className="flex flex-col">
                  <span className="font-semibold text-dark3">
                    Erstattung{" "}
                    {selectedInsurance && (selectedInsurance.private ? "bis zu 100%" : `${selectedInsurance.refund}%`)}
                  </span>
                  <span className="text-sm text-dark3">(Nach Kursabschluss)</span>
                </div>
              )}
              {variant === "v2" && (
                <div className="flex flex-col">
                  <span className="font-semibold text-green1">
                    Erstattung{" "}
                    {selectedInsurance && (selectedInsurance.private ? "bis zu 100%" : `${selectedInsurance.refund}%`)}
                  </span>
                  <span className="text-xs text-dark3">(nach Kursabschluss)</span>
                </div>
              )}

              <span className="font-semibold text-dark1">
                -
                {selectedInsurance
                  ? (
                      (selectedInsurance.price * (selectedInsurance.private ? 100 : selectedInsurance.refund)) /
                      100
                    ).toFixed(2)
                  : "79,99"}{" "}
                €
              </span>
            </div>
            <div className="flex items-center justify-between border-t-2 border-dark1 pt-4">
              <span className="text-dark1 font-bold">Am Ende zahlst du effektiv:</span>
              <span className="text-dark1 font-bold">
                {selectedInsurance
                  ? (
                      selectedInsurance.price *
                      (1 - (selectedInsurance.private ? 100 : selectedInsurance.refund) / 100)
                    ).toFixed(2)
                  : "0"}{" "}
                €
              </span>
            </div>
          </div>
        </div>

        <Button
          primary
          label="Weiter"
          className="mb-4 w-full"
          onClick={handleContinue}
          disabled={!selectedInsurance}
          customBg={{ default: "bg-green1", hover: "hover:bg-green1" }}
        />

        {selectedInsurance && (
          <p className="mb-4 px-4 text-center text-sm text-dark4">
            {selectedInsurance.private ? (
              <>
                <span className="font-bold">Bis zu 100% Geld zurück!</span>
                <br />
                *Die genaue Erstattungssumme hängt von deinem gewählten Tarif ab. Diesen erfährst du von deiner
                Krankenkasse.
              </>
            ) : (
              <>
                {variant === "v1" && (
                  <>
                    <span className="font-bold">{selectedInsurance.refund}% Geld zurück!</span>
                    <br />
                    Nach Kursabschluss kannst du von deiner Krankenkasse das Geld zurück erhalten.
                  </>
                )}
                {variant === "v2" && (
                  <>
                    <span className="font-bold">{selectedInsurance.refund}% Geld zurück!</span>
                    <br />
                    Nach Abschluss deines 8-Wochen-Kurses kannst du dir {selectedInsurance.refund}% der Kosten von
                    deiner Krankenkasse zurückholen. Die Teilnahmegebühr von{" "}
                    {selectedInsurance?.price.toFixed(2) || "119,00"} € wird erst einmal ganz bequem auf Rechnung
                    geregelt – jedoch hast du sofort vollen Zugriff auf Fastic Plus.
                  </>
                )}
              </>
            )}
          </p>
        )}
      </div>
    </div>
  )
}

export default InsuranceModal
